import React from 'react'
import Layout from '../layouts'
import Write from '../images/write.jpg'
import { graphql } from 'gatsby'

const VideoPage = ({ data }) => (
	<Layout head={{ title: 'Videos' }}>
		<div className="videos">
			<div
				id="hero-section"
				className="bg-cover"
				style={{ background: `url("${Write}")` }}
			>
				<div className="container">
					<div className="slim-content">
						<h1 className="text-red">Videos</h1>
					</div>
				</div>
			</div>
			<div className="container-fluid pt-5">
				<div className="row">
					{data.allVideosJson.edges.map(video => (
						<div className="col-md-6" key={video.node.id}>
							<div className="video-wrap mb-5">
								<h4>{video.node.title}</h4>
								<iframe
									title="Sales Leadership Video"
									src={`https://www.youtube.com/embed/${video.node.videoId}`}
									frameborder="0"
									allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
									allowfullscreen="true"
								/>
							</div>
						</div>
					))}
				</div>
			</div>
		</div>
	</Layout>
)

export default VideoPage

export const pageQuery = graphql`
	query {
		allVideosJson {
			edges {
				node {
					id
					title
					videoId
				}
			}
		}
	}
`
